import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './userSection.scss';

class UserSection extends Component {
  static propTypes = {
    classes: PropTypes.string,
    image: PropTypes.string,
    text: PropTypes.string
  }

  render() {
      return(
          <div className={`user-section ${this.props.classes || ''}`}>
              {this.props.children}
          </div>
      )
  }
}

export default UserSection;
