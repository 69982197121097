import React, { Component } from 'react';
import Header from './components/Header';
import UserSection from './components/UserSection';
import clientImg from './img/booked-screen.png';
import providerImg from './img/scheduled-screen.png';
import './app.scss';


class App extends Component {
  render() {
    return (
      // set all classnames to lowercase
      <div className="app">
        <div className="content-wrapper">
          <Header />
          <UserSection classes="client">
            <div className="user-section--content">
              <p className="user-section--text">
                Find the right hairstylist for you. <br />
                Go to them or maybe they can come to you.
              </p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfY1LnWqrCNoz951TYesKCiOdJkt5A10R7CHdO8eGikg6jrxg/viewform" rel="noreferrer noopener"  target="_blank" >
                <button className="button user-section--button">Sign up to be a beta user</button>
              </a>
              <div className="social-media-container">
                <a href="https://twitter.com/beautyhausco" rel="noreferrer noopener"  target="_blank" className="social-media-icon">
                      <i className="fab fa-twitter"></i>
                  </a>
                <a href="https://www.instagram.com/beautyhausco/" rel="noreferrer noopener"  target="_blank"  className="social-media-icon">
                      <i className="fab fa-instagram"></i>
                  </a>
               </div>
            </div>
            <div className="user-section--image-container">
              <img className="user-section--image" src={clientImg} alt="" />
            </div>
          </UserSection>
          <UserSection classes="provider">
            <div className="user-section--content">
              <h3 className="user-section--title">Are you a stylist?</h3>
              <p className="user-section--text">
                Sign up to sell and book your services. <br />
                Manage your clients, etc.
              </p>   
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfY1LnWqrCNoz951TYesKCiOdJkt5A10R7CHdO8eGikg6jrxg/viewform" rel="noreferrer noopener"  target="_blank" >
                <button className="button">
                  Sign up to be a beta user
                </button>
              </a>         
            </div>
            <div className="user-section--image-container">
              <img className="user-section--image" src={providerImg} alt="" />
            </div>
          </UserSection>
        </div>
      </div>
    );
  }
}

export default App;
