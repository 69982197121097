import React, { Component } from 'react';
import logo from '../img/beauty_haus_outlined_logo.svg';
import './header.scss';

class ClientSection extends Component {
    render() {
        return(
          <header className="app-header">
            <div className="logo">
                <h1><img src={logo} alt="Beauty Haus logo" /></h1>
            </div>
            <div className="social-media-container">
                <a href="https://twitter.com/beautyhausco" className="social-media-icon">
                    <i className="fab fa-twitter"></i>
                </a>
                <a href="https://www.instagram.com/beautyhausco/" className="social-media-icon">
                    <i className="fab fa-instagram"></i>
                </a>
             </div>
          </header>
        )
    }
}

export default ClientSection;
